.userAccount {
  display: flex;
  // flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0884ff;
  color: white;
  text-align: center;
  // padding: 5px;

  div {
    &:nth-child(2) {
      font-size: 14px;
    }
  }

  // @media only screen and (max-width: 900px) {
  //   align-items: flex-start;
  // }
}
