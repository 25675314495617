@import "../../../styles/index.scss";

.form {
  width: 100%;
  min-width: 300px;
  max-width: 400px;

  .field {
    padding: 1rem 0rem;
  }
  .forgot {
    color: $primary-color;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
  }
  .heading {
    h2 {
      text-align: center;
      font-size: 1.8rem;
    }
  }
}
