@import "../../../styles//index.scss";

.page_wrapper {
  height: 95vh;
}
.wrapper {
  width: 94%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;

  .login_button {
    background-color: white;
    color: black;
    text-transform: none;
    min-width: 250px;
    width: 30%;
    max-width: 450px;
    padding: 10px;
    margin-top: 15px;

    .logo {
      width: 20px;
      height: 20px;
    }
  }

  .create_account {
    color: $gray-light;
    font-weight: 500;
    margin-top: 2rem;
    min-width: 250px;
    width: 30%;
    max-width: 450px;

    .sign_up {
      color: $primary-color;
      font-weight: 600;
    }
  }
}
