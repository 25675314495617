@import "./spacing.scss";
@import "./typography.scss";

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: blue;
}
p {
  margin: 0px;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
}
input {
  text-transform: inherit;
}

$gray-darkest: #1c1c1c;
$gray-dark: #4c4c4c;
$gray-light: #7c7c7c;
$gray-lightest: #acacac;
$border-gray: #dadce0;
$primary-color: #007aff;
$primary-light: #007aff10;
$warning-color: #ffcc00;
$warning-light: #ffcc0010;
$error-color: #ff3b30;
$error-light: #ff3b3010;
$success-color: #34c759;
$success-light: #34c75910;

:root {
  --primary-color: #007aff;
  --gray-light: #7c7c7c;
  --gray-lightest: #acacac;
  --gray-darkest: #1c1c1c;
  --warning-color: #ffcc00;
  --error-color: #ff3b30;
  --success-color: #34c759;
  --border-gray: #dadce0;
}

@mixin border-bottom {
  border-bottom: 1px solid $border-gray;
}

@mixin flex {
  display: flex;
  align-items: center;
}

// Stripe card element styling

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

// End of stripe card element styling

// Start of dot loader styling
.triple-spinner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid green;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: yellow;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: red;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
// End of dot loader styling

// maintenance page
.maintenance-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 50%;
    max-width: 600px;
    min-width: 300px;
  }
  h1 {
    text-align: center;
  }
}

// end of maintenance page

.flex-container {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.direction-column {
  flex-direction: column;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.justify-between {
  justify-content: space-between;
}
